import { GeneralObject } from 'types';

import {
  initialize as logrocketInitialize,
  trackIdentity as logrocketTrackIdentity,
  trackException as logrocketTrackException,
  trackError as logrocketTrackError,
  trackEvent as logrocketTrackEvent,
  trackPage as logrocketTrackPage
} from './logrocket';
import {
  initialize as gaInitialize,
  trackIdentity as gaTrackIdentity,
  trackEvent as gaTrackEvent,
  trackPage as gaTrackPage
} from './ga';

// some tools we use
const STORYBOOK_RUNNING = process.env.STORYBOOK;
const JEST_RUNNING =
  process.env.JEST_WORKER_ID !== undefined || process.env.NODE_ENV === 'test';

// don't enable analytics for internal tools
const ANALYTICS_ENABLED = !STORYBOOK_RUNNING && !JEST_RUNNING;

/**
 * initialize analytics tools if needed
 */
export const initializeAnalytics = () => {
  if (ANALYTICS_ENABLED) {
    logrocketInitialize();
    gaInitialize();
  }
};

/**
 * captures a user identity for analytics
 */
export const captureUserIdentityForAnalytics = (
  userId: string,
  additionalProperties: GeneralObject = {}
) => {
  if (ANALYTICS_ENABLED) {
    logrocketTrackIdentity({
      userId: userId,
      properties: additionalProperties
    });
    gaTrackIdentity({ userId: userId });
  }
};

/**
 * captures a page view for analytics
 */
export const capturePageViewForAnalytics = (
  pageName = '',
  additionalProperties = {}
) => {
  if (ANALYTICS_ENABLED) {
    if (pageName) {
      logrocketTrackPage({
        name: pageName,
        properties: additionalProperties
      });
      gaTrackPage({
        name: pageName,
        properties: additionalProperties
      });
    }
  }
};

/**
 * captures an event for analytics
 */
export const captureEventForAnalytics = (
  eventName = '',
  additionalProperties = {}
) => {
  if (ANALYTICS_ENABLED) {
    if (eventName) {
      logrocketTrackEvent({
        name: eventName,
        properties: additionalProperties
      });
      gaTrackEvent({
        name: eventName,
        properties: additionalProperties
      });
    }
  }
};

/**
 * captures an exception
 */
export const captureExceptionForAnalytics = (
  error: Error,
  additionalProperties = {}
) => {
  if (ANALYTICS_ENABLED) {
    logrocketTrackException({ error: error, properties: additionalProperties });
  }
};

/**
 * captures an error
 */
export const captureErrorForAnalytics = (
  errorMessage: string,
  additionalProperties = {}
) => {
  if (ANALYTICS_ENABLED) {
    logrocketTrackError({
      error: errorMessage,
      properties: additionalProperties
    });
  }
};
