import LogRocket from 'logrocket';

import { LOGROCKET_APP_ID, API_ENV } from '../constants/env';

/**
 * tracking enabled if logrocket app id defined
 */
const CAN_TRACK = !!LOGROCKET_APP_ID;

/**
 * initialize this tool
 */
export const initialize = () => {
  if (CAN_TRACK) {
    LogRocket.init(LOGROCKET_APP_ID, {
      network: {
        requestSanitizer: request => {
          // ignore google analytics calls
          if (request.url.toLowerCase().indexOf('google-analytics') !== -1) {
            return null;
          }

          const REDACTED_VALUE = '** REDACTED **';

          // sanitize the auth headers...
          if (request.headers['Authorization']) {
            request.headers['Authorization'] = REDACTED_VALUE;
          } else if (request.headers['authorization']) {
            request.headers['authorization'] = REDACTED_VALUE;
          }

          // sanitize the password while creating an account...
          if (request.body) {
            const contentType =
              request.headers['Content-Type'] ??
              request.headers['content-type'] ??
              '';

            if (contentType === 'application/json') {
              const requestBody = JSON.parse(request.body);

              // if the request contains a password, obscure if from view to/from LogRocket
              if (requestBody.password) {
                requestBody.password = REDACTED_VALUE;
                request.body = JSON.stringify(requestBody);
              } else if (requestBody?.response?.password) {
                requestBody.response.password = REDACTED_VALUE;
                request.body = JSON.stringify(requestBody);
              }
            }
          }

          // otherwise log the request normally
          return request;
        }
      }
    });
  }
};

/**
 * Method for tracking the identity in logrocket
 * @param {object} obj - options for identity tracking
 * @param {string} obj.userId - user id of the user to track
 * @param {object} [obj.properties] - properties of the user to track
 */
export const trackIdentity = ({ userId, properties = {} }) => {
  if (CAN_TRACK) {
    LogRocket.identify(userId, {
      ...properties
    });
  }
};

/**
 * Method for tracking exception logrocket
 * @param {object} obj - options for identity tracking
 * @param {Error} obj.error - the error to track
 * @param {object} [obj.properties] - properties of the user to track
 */
export const trackException = ({ error, properties = {} }) => {
  if (CAN_TRACK) {
    LogRocket.captureException(error, {
      tags: {
        stage: API_ENV
      },
      extra: properties
    });
  }
};

/**
 * Method for tracking error logrocket
 * @param {object} obj - options for identity tracking
 * @param {string} obj.error - the error to track
 * @param {object} [obj.properties] - properties of the user to track
 */
export const trackError = ({ error, properties = {} }) => {
  if (CAN_TRACK) {
    LogRocket.captureMessage(error, {
      tags: {
        stage: API_ENV
      },
      extra: properties
    });
  }
};

/**
 * Method for event tracking in logrocket
 * @param {object} obj - options for event tracking
 * @param {string} obj.name - name of event to track.
 * @param {object} [obj.properties] - properties of event to track.
 */
export const trackEvent = ({ name, properties = {} }) => {
  if (CAN_TRACK) {
    LogRocket.track(`Event: ${name}`, properties);
  }
};

/**
 * Method for page tracking in log rocket
 * @param {object} obj - options for page tracking
 * @param {string} obj.name - name of page to tracking
 * @param {object} [obj.properties] - properties of page to track, include: url, title, referrer, path.
 */
export const trackPage = ({ name, properties = {} }) => {
  if (CAN_TRACK) {
    LogRocket.track(`PageView: ${name}`, properties);
  }
};
